var theme = {
  plain: {
    color: "#F8F8F2",
    backgroundColor: "#272822"
  },
  styles: [
    {
      types: ["comment"],
      style: {
        color: "#8292a2"
      }
    },
    {
      types: ["string"],
      style: {
        color: "#e6db74"
      }
    },
    {
      types: ["keyword", "logical", "operator", "assignment", "arithmetic"],
      style: {
        color: "#f92672"
      }
    },
    {
      types: ["escape", "boolean", "number"],
      style: {
        color: "#ae81ff"
      }
    },
    {
      types: ["function", "class-name"],
      style: {
        color: "#66d9ef"
      }
    },
    {
      types: ["important"],
      style: {
        color: "#fd971f"
      }
    }
  ]
};

export default theme;
